import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import routes from "../components/navigation/routes";
import LayoutCosmos from "../components/element/layout/LayoutCosmos";

const seo = {
  title: "404 Page not found - Oberion",
  description: null,
  // canonical for 404 page not recommended
};
const heading = "404 Page not found";
const subheading = ["These links might be helpful instead:"];
const buttons = [
  <Button
    component={GatsbyLink}
    to={routes.home.to} variant="outlined"
    color="secondary"
    fullWidth
  >{routes.home.caption}
  </Button>,
  <Button
    component={GatsbyLink}
    to={routes.blog.to}
    variant="outlined"
    color="secondary"
    fullWidth
  >{routes.blog.caption}
  </Button>,
  <Button
    component={GatsbyLink}
    to={routes.about.to}
    variant="outlined"
    color="secondary"
    fullWidth
  >{routes.about.caption}
  </Button>,
];
const Error404 = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default Error404;
